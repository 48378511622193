<template>
  <div class="app__container overflow-visible">
    <DefaultHeader v-if="ROUTER.currentRoute.value.meta.hasSidebar" />
    <div class="app__main">
      <div class="h-full bg-gray-800">
        <!-- <div v-if="ROUTER.currentRoute.value.path == '/integrations'"> -->
          <DefaultSidebar v-if="ROUTER.currentRoute.value.meta.hasSidebar && sessionStore.role != ROLE.ROLE_USER"/>
        <!-- </div>
        <div v-else>
          <DefaultSidebar v-if="ROUTER.currentRoute.value.meta.hasSidebar" />
        </div> -->
      </div>
      <main class="app__main_wrapper bg-gray-50 prv-events flex flex-col" tabindex="0">
        <div class="flex-grow grow">
          <router-view />
        </div>
        <div class="block text-xs w-full my-8 grow-0">
          <div class="flex justify-center space-x-2 text-gray-500">
            <span>© 2024</span>
            <a
              href="https://www.preava.com/legal/privacy-notice?hsLang=en"
              target="_blank"
              class="text-blue-500 hover:text-blue-600"
              >Privacy Policy</a
            >
            <span class="text-gray-300">|</span>
            <a
              href="https://www.preava.com/legal/application-privacy-notice?hsLang=en"
              target="_blank"
              class="text-blue-500 hover:text-blue-600"
              >Application Privacy Policy</a
            >
          </div>
        </div>
      </main>
    </div>
  </div>
  <!-- 
    Display the 'open in desktop only' screen when viewed in small screens.
    This will also automatically work on Login page.
  -->
  <DesktopOnlyScreen />
</template>

<script setup>
import { Role as ROLE } from "@preava/preava-prevent-api-grpc-web-js/enums_pb";
import ROUTER from "@/router";

import DesktopOnlyScreen from "@/components/screen/DesktopOnlyScreen";
import DefaultHeader from "@/components/header/DefaultHeader";
import DefaultSidebar from "@/components/sidebar/DefaultSidebar";

import { useSessionStore } from "@/modules/v2/store/session";
const sessionStore = useSessionStore();

</script>

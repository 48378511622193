import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "@/App.vue";
import ROUTER from "@/router";
import { setupAuth, getAuthConfig } from '@/utilities/auth'
import '@/styles/index.css'

/** Set the default title */
import title_mixin from '@/mixins/title'
document.title = "Welcome | " + location.host

/** Initialize app */
getAuthConfig().then(authConfig => {
  /** Create the app */
  let app = createApp(App).use(ROUTER).mixin(title_mixin)
  
  app.use(createPinia())
  
  function callbackRedirect(appState) {
    ROUTER.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : '/'
    );
  }
  /** Setup authentication */
  setupAuth(authConfig, callbackRedirect).then((auth) => {
    app.use(auth).mount('#app')
  })

})
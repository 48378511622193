import ROUTER from "@/router";

function getTitle(vm) {

  let site_title = '';
  if(ROUTER.currentRoute.value.name == "Login"){
    site_title = "Welcome to Preava Prevent";
  }else{
    site_title = ROUTER.currentRoute.value.name;
  }
  // const { site_title } = vm.$options
  
  if (site_title) {
    return typeof site_title === 'function'
      ? site_title.call(vm)
      : site_title
  }
}
export default {
  created() {
    const site_title = getTitle(this)
    if (site_title) {
      document.title = site_title + " | Preava Prevent Admin Dashboard"
    }
  }
}
import createAuth0Client from "@auth0/auth0-spa-js";
import { computed, reactive, watchEffect } from "vue";
import axios from "axios";
import { getWww } from "prv-js-common/endpoints";
import { deleteFromLocalStorage, retrieveFromLocalStorage } from "@/utilities";

let client;
const state = reactive({
  loading: true,
  isAuthenticated: false,
  user: {},
  popupOpen: false,
  error: null
});

async function loginWithPopup() {
  state.popupOpen = true;

  try {
    await client.loginWithPopup(0);
  } catch (e) {
    console.error(e);
  } finally {
    state.popupOpen = false;
  }

  state.user = await client.getUser();
  state.isAuthenticated = true;
}

async function handleRedirectCallback() {
  state.loading = true;

  try {
    await client.handleRedirectCallback();
    state.user = await client.getUser();
    state.isAuthenticated = true;
  } catch (e) {
    state.error = e;
  } finally {
    state.loading = false;
  }
}

export function loginWithRedirect(o) {
  // console.log("Logging in with redirect")
  return client.loginWithRedirect(o);
}

function getIdTokenClaims(o) {
  return client.getIdTokenClaims(o);
}

export function getTokenSilently(o) {
  return client.getTokenSilently(o);
}

function getTokenWithPopup(o) {
  return client.getTokenWithPopup(o);
}

function checkSession(o) {
  return client.checkSession(o);
}

export function logout(o) {
  // deleting the x-trace-id local storage value
  let xTraceId = retrieveFromLocalStorage();
  if(xTraceId != null){
    console.log("deleting x-trace-id from the local storage...")
    deleteFromLocalStorage();
  }
  return client.logout(o);
}

const authPlugin = {
  isAuthenticated: computed(() => state.isAuthenticated),
  loading: computed(() => state.loading),
  user: computed(() => state.user),
  getIdTokenClaims,
  getTokenSilently,
  getTokenWithPopup,
  handleRedirectCallback,
  loginWithRedirect,
  loginWithPopup,
  logout,
  checkSession
};

export const routeGuard = (to, from, next) => {
  const { isAuthenticated, loading } = authPlugin;

  // console.log(isAuthenticated)

  const verify = () => {
    // If the user is authenticated, continue with the route
    if (isAuthenticated.value) {
      return next();
    }

    // Otherwise, log in
    // loginWithRedirect({ appState: { targetUrl: to.fullPath } })
    return next({ path: "/login" });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!loading.value) {
    return verify();
  }

  // Watch for the loading property to change before we check isAuthenticated
  watchEffect(() => {
    if (loading.value === false) {
      return verify();
    }
  });
};

export const redirectIfLogged = (to, from, next) => {
  const { user } = authPlugin;
  return user.value ? next({ path: "/" }) : next();
};

export const setupAuth = async (options, callbackRedirect) => {
  client = await createAuth0Client({
    ...options
  });

  try {
    // If the user is returning to the app after authentication
    if (
      window.location.search.includes("code=") &&
      window.location.search.includes("state=")
    ) {
      // handle the redirect and retrieve tokens
      const { appState } = await client.handleRedirectCallback();

      // Notify subscribers that the redirect callback has happened, passing the appState
      // (useful for retrieving any pre-authentication state)
      callbackRedirect(appState);
    }
  } catch (e) {
    state.error = e;
    console.log("[Auth0 SPA] Encountered an error while setting up authentication => ", e) // P.S. Currently testing
  } finally {
    // Initialize our internal authentication state
    state.isAuthenticated = await client.isAuthenticated();
    state.user = await client.getUser();
    state.loading = false;
  }

  return {
    install: app => {
      app.config.globalProperties.$auth = authPlugin;
    }
  };
};

const fetchDevOrProdConfig = () => {
  return new Promise(resolve => {
    // Check ENV if currently on DEV or PROD
    if (process.env.NODE_ENV === "development") {
      // If DEV, fetch via .env
      // console.log("Currently on DEV mode with the following credentials:")
      let config = {
        domain: process.env.VUE_APP_AUTH0_TENANT,
        client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
        redirect_uri: process.env.VUE_APP_AUTH0_REDIRECT_URI,
        audience: process.env.VUE_APP_AUTH0_AUDIENCE,
        useRefreshTokens: true,
        cacheLocation: "memory"
      };
      resolve(config);
    } else {
      // If PROD, fetch via auth0.json
      // console.log("Currently on PROD mode")
      // console.log("Fetching Auth0 Credentials")

      axios
        .get(getWww(location.host) + "/auth0.json")
        .then(response => {
          // console.log('Received the following ProductionCreds:')
          // console.log(response)
          let config = {
            domain: response.data.tenant,
            client_id: response.data.client_id,
            redirect_uri: response.data.redirect_uri,
            audience: response.data.audience,
            useRefreshTokens: true,
            cacheLocation: "memory"
          };
          resolve(config);
        })
        .catch(error => {
          console.log(error);
        });
    }
  });
};

export const getAuthConfig = async () => {
  let authConfig = await fetchDevOrProdConfig();
  return authConfig;
};

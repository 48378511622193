<!-- This is the Header's User Menu located at the top-most right of the screen -->

<template>
  <div class="header__user-menu__wrapper">
    <div class="header__container--empty"></div>
    <div class="header__container--main">
      <div class="header__main--wrapper">
        <!-- 
          [HACK]: 
            
          We need to add this Cookie button but absolutely hide it. 
          This is a workaround for the original cookie button to be 
          hidden and dynamically gets clicked for display.
          - by @johnnypreavacom

        -->
        <!-- <button
          id="ot-sdk-btn"
          class="ot-sdk-show-settings"
          style="display: none !important"
        >
          Cookies Settings
        </button> -->

        <!-- External Links Menu -->
        <Menu as="div" class="header__menu">
          <MenuButton class="header__menu__button">
            <span class="sr-only">Open external links menu</span>
            <QuestionMarkCircleIcon
              class="header__menu__icon--external"
              aria-hidden="true"
            />
          </MenuButton>

          <transition
            enter-active-class="header__enter-active"
            enter-from-class="header__enter-from"
            enter-to-class="header__enter-to"
            leave-active-class="header__leave-active"
            leave-from-class="header__leave-from"
            leave-to-class="header__leave-to"
          >
            <MenuItems class="header__menu-items">
              <MenuItem
                v-for="item in navigation"
                v-slot="{ active }"
                :key="item.name"
              >
                <a
                  :href="item.href"
                  target="_blank"
                  :class="[
                    active ? 'header__item--active' : '',
                    'header__item header__item--external',
                  ]"
                >
                  {{ item.name }}
                  <ExternalLinkIcon class="header__icon--external" />
                </a>
              </MenuItem>
              <div class="header__version">Version {{ version }}</div>
            </MenuItems>
          </transition>
        </Menu>

        <!-- User Menu -->
        <Menu as="div" class="header__menu">
          <MenuButton class="header__menu__button">
            <span class="sr-only">Open user menu</span>
            <img
              v-if="this.$auth.user.value && this.$auth.user.value.picture"
              class="header__menu__icon--user"
              :src="this.$auth.user.value.picture"
              alt="User image"
            />
            <DefaultUserIcon v-else class="header__menu__icon--user" />
          </MenuButton>

          <transition
            enter-active-class="header__enter-active"
            enter-from-class="header__enter-from"
            enter-to-class="header__enter-to"
            leave-active-class="header__leave-active"
            leave-from-class="header__leave-from"
            leave-to-class="header__leave-to"
          >
            <MenuItems class="header__menu-items">
              <!-- <MenuItem v-slot="{ active }">
                <a
                  href="#"
                  @click="showCookieSettings()"
                  :class="[
                    active ? 'header__item--active' : '',
                    'header__item',
                  ]"
                >
                  Cookies Settings
                </a>
              </MenuItem> -->

              <MenuItem v-slot="{ active }">
                <a
                  href="#"
                  @click="logout()"
                  :class="[
                    active ? 'header__item--active' : '',
                    'header__item flex flex-col space-y-1',
                  ]"
                >
                <strong class="font-semibold">{{ ($auth.user) ? $auth.user.value.email : null}}</strong>
                <span>Sign Out </span>
                </a>
              </MenuItem>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </div>
  </div>
</template>
<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import {
  QuestionMarkCircleIcon,
  ExternalLinkIcon,
} from "@heroicons/vue/outline";
import DefaultUserIcon from "@/assets/icons/DefaultUserIcon.vue";
import { ref } from "vue";

const navigation = [
  { name: "Knowledge Base", href: "https://support.preava.com/" },
  { name: "Feedback", href: "https://www.preava.com/feedback" },
  { name: "File a Support Ticket", href: "https://support.preava.com/tickets-view/new" },
  
];

export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    QuestionMarkCircleIcon,
    ExternalLinkIcon,
    DefaultUserIcon,
  },
  props: ["version"],
  setup() {
    const isOpen = ref(false);
    return {
      navigation,
      isOpen,
    };
  },
  data() {
    return {
      logoutUrl: location.protocol + "//" + location.host,
    };
  },
  methods: {
    logout() {
      this.$auth.logout({ returnTo: this.logoutUrl });
    },
    showCookieSettings() {
      document.getElementById("ot-sdk-btn").click();
    },
  },
};
</script>
<style scoped>
@import "../header.css";
</style>
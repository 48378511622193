<template>
  <div>
    <svg
      width="164"
      height="27"
      viewBox="0 0 164 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="w-full"
    >
      <path
        d="M33.4901 11.4468C34.1836 11.4468 34.7858 11.5636 35.2967 11.7972C35.8077 12.0234 36.2019 12.3519 36.4793 12.7826C36.7567 13.206 36.8953 13.706 36.8953 14.2826C36.8953 14.8593 36.7567 15.3593 36.4793 15.7827C36.2019 16.2061 35.8077 16.5346 35.2967 16.7681C34.7858 16.9944 34.1836 17.1076 33.4901 17.1076H32.1543V19.1113H29.9863V11.4468H33.4901ZM33.3587 15.3995C33.804 15.3995 34.1398 15.3046 34.3661 15.1148C34.5923 14.9177 34.7055 14.6403 34.7055 14.2826C34.7055 13.925 34.5923 13.6476 34.3661 13.4505C34.1398 13.2534 33.804 13.1549 33.3587 13.1549H32.1543V15.3995H33.3587Z"
        fill="#1D293F"
      ></path>
      <path
        d="M43.3832 17.0747H42.2007V19.1113H40.0327V11.4468H43.5365C44.23 11.4468 44.8322 11.5636 45.3432 11.7972C45.8541 12.0234 46.2483 12.3519 46.5257 12.7826C46.8031 13.206 46.9418 13.706 46.9418 14.2826C46.9418 14.8374 46.8104 15.3228 46.5476 15.7389C46.2921 16.1477 45.9235 16.4689 45.4417 16.7024L47.0951 19.1113H44.7738L43.3832 17.0747ZM44.7519 14.2826C44.7519 13.925 44.6388 13.6476 44.4125 13.4505C44.1862 13.2534 43.8504 13.1549 43.4051 13.1549H42.2007V15.3995H43.4051C43.8504 15.3995 44.1862 15.3046 44.4125 15.1148C44.6388 14.9177 44.7519 14.6403 44.7519 14.2826Z"
        fill="#1D293F"
      ></path>
      <path
        d="M56.3717 17.436V19.1113H50.2182V11.4468H56.2293V13.122H52.3642V14.414H55.7695V16.0345H52.3642V17.436H56.3717Z"
        fill="#1D293F"
      ></path>
      <path
        d="M64.7903 17.6222H61.5493L60.9471 19.1113H58.7354L62.1187 11.4468H64.2538L67.6481 19.1113H65.3925L64.7903 17.6222ZM64.1553 16.0236L63.1698 13.5709L62.1844 16.0236H64.1553Z"
        fill="#1D293F"
      ></path>
      <path
        d="M77.6894 11.4468L74.4046 19.1113H72.2695L68.9956 11.4468H71.3388L73.4192 16.4397L75.5433 11.4468H77.6894Z"
        fill="#1D293F"
      ></path>
      <path
        d="M85.0863 17.6222H81.8453L81.2431 19.1113H79.0314L82.4147 11.4468H84.5498L87.9441 19.1113H85.6885L85.0863 17.6222ZM84.4513 16.0236L83.4658 13.5709L82.4804 16.0236H84.4513Z"
        fill="#1D293F"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9669 4.32422C18.8819 4.02663 22.4108 3.17315 24.9961 0.738281C24.5137 2.18534 23.7923 3.27294 22.9265 4.08515C23.5433 3.70183 24.1326 3.25741 24.6797 2.74219C24.0528 4.62259 23.0224 5.89598 21.796 6.7469C22.1553 7.40585 22.3594 8.16151 22.3594 8.96484V13.9123C22.3594 16.7718 20.0413 19.0898 17.1818 19.0898H13.5862C12.1422 19.0898 10.9713 20.2592 10.9688 21.7026V23.7815C10.9688 24.3946 11.1903 24.9871 11.5927 25.4498C11.761 25.6434 11.6235 25.9452 11.3669 25.9452H10.7726C7.15303 25.9452 4.21875 23.012 4.21876 19.3924L4.21876 17.148L4.21875 17.1391V9.59766C4.21875 6.68522 6.57975 4.32422 9.49219 4.32422H15.9669ZM12.8672 10.8633C11.8187 10.8633 10.9688 11.7132 10.9688 12.7617H13.8164C14.8649 12.7617 15.7148 11.9118 15.7148 10.8633H12.8672Z"
        fill="#3676CA"
      ></path>
      <path
        d="M22.3066 8.22656C19.5669 9.34206 16.0403 9.34736 13.9746 9.21066C18.6757 9.10654 21.8023 6.75 21.8023 6.75C22.0957 7.27734 22.2012 7.69922 22.3066 8.22656Z"
        fill="#1F5BAA"
      ></path>
      <path
        d="M15.7117 10.8636C17.2871 10.8591 18.9519 10.7868 20.7742 9.9668C18.9949 11.1704 17.1508 12.0154 15.3457 12.0234C15.4719 11.7694 15.7117 11.1665 15.7117 10.8636Z"
        fill="#1F5BAA"
      ></path>
      <path
        d="M10.9688 12.7617H13.3945C11.8652 13.4473 11.2324 14.1328 10.9688 20.5664V12.7617Z"
        fill="#1F5BAA"
      ></path>
      <path
        d="M97.2405 19.2188V16.5728H99.1541C100.926 16.5728 101.895 15.3797 101.895 13.9622C101.895 12.5329 100.926 11.3398 99.1541 11.3398H95.2087V19.2188H97.2405ZM98.8824 14.8009H97.2405V13.1117H98.8824C99.4258 13.1117 99.8274 13.4188 99.8274 13.9622C99.8274 14.4938 99.4258 14.8009 98.8824 14.8009ZM112.112 19.2188L110.505 16.3247C111.238 16.0648 112.076 15.3324 112.076 13.9622C112.076 12.4974 111.084 11.3398 109.336 11.3398H105.39V19.2188H107.422V16.5728H108.462L109.785 19.2188H112.112ZM109.029 14.8009H107.422V13.1117H109.029C109.56 13.1117 110.009 13.4188 110.009 13.9504C110.009 14.5056 109.56 14.8009 109.029 14.8009ZM121.567 19.2188V17.4469H117.823V16.1121H121.485V14.3402H117.823V13.1117H121.567V11.3398H115.791V19.2188H121.567ZM130.016 19.2188L132.993 11.3398H130.689L128.74 17.0216L126.803 11.3398H124.5L127.464 19.2188H130.016ZM141.942 19.2188V17.4469H138.198V16.1121H141.859V14.3402H138.198V13.1117H141.942V11.3398H136.166V19.2188H141.942ZM153.037 19.2188V11.3398H150.993V15.675L147.804 11.3398H145.713V19.2188H147.745V14.6591L151.076 19.2188H153.037ZM160.84 19.2188V13.1117H163.037V11.3398H156.599V13.1117H158.808V19.2188H160.84Z"
        fill="#A2A3AF"
      ></path>
    </svg>
  </div>
</template>
import { createRouter, createWebHistory } from "vue-router";
import { routeGuard, redirectIfLogged } from "@/utilities/auth";

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: { path: "/analytics" },
  },
  {
    path: '/analytics',
    name: 'Analytics',
    beforeEnter: routeGuard,
    meta: { hasSidebar: true },
    component: function () {
      return import(/* webpackChunkName: "analytics" */ '../modules/analytics/Analytics.vue')
    }
  },
  {
    path: "/settings",
    name: "Settings",
    beforeEnter: routeGuard,
    meta: { hasSidebar: true },
    component: function () {
      return import(
        /* webpackChunkName: "settings" */ "../modules/settings/Settings.vue"
      );
    },
  },
  {
    path: "/system-information",
    name: "System information",
    beforeEnter: routeGuard,
    meta: { hasSidebar: true },
    component: function () {
      return import(
        /* webpackChunkName: "settings" */ "../modules/tenancy/Tenancy.vue"
      );
    },
  },
  {
    path: "/users",
    name: "Users",
    beforeEnter: routeGuard,
    meta: { hasSidebar: true },
    component: function () {
      return import(
        /* webpackChunkName: "users" */ "../modules/users/UsersV2.vue"
      );
    },
  },
  {
    path: "/logs",
    name: "Logs",
    beforeEnter: routeGuard,
    meta: { hasSidebar: true },
    component: function () {
      return import(
        /* webpackChunkName: "events" */ "../modules/events/Events.vue"
      );
    },
  },
  {
    path: "/integrations",
    name: "Integrations",
    beforeEnter: routeGuard,
    meta: { hasSidebar: true },
    component: function () {
      return import(
        /* webpackChunkName: "endpoints" */ "../modules/integrations/Integrations.vue"
      );
    },
  },
  {
    path: "/allowlist",
    name: "Allowlist",
    beforeEnter: routeGuard,
    meta: { hasSidebar: true },
    component: function () {
      return import(
        /* webpackChunkName: "events" */ "../modules/allowlist/Allowlist.vue"
      );
    },
  },
  {
    path: "/denylist",
    name: "Denylist",
    beforeEnter: routeGuard,
    meta: { hasSidebar: true },
    component: function () {
      return import(
        /* webpackChunkName: "events" */ "../modules/denylist/Denylist.vue"
      );
    },
  },
  {
    path: "/policies",
    name: "Policies",
    beforeEnter: routeGuard,
    meta: { hasSidebar: true },
    component: function () {
      return import(
        /* webpackChunkName: "events" */ "../modules/policies/Policies.vue"
      );
    },
  },
  {
    path: "/rules",
    name: "Rules",
    beforeEnter: routeGuard,
    meta: { hasSidebar: true },
    component: function () {
      return import(
        /* webpackChunkName: "rules" */ "../modules/rules/RulesTab.vue"
      );
    },
  },
  {
    path: "/login",
    name: "Login",
    beforeEnter: redirectIfLogged,
    meta: { hasSidebar: false },
    component: function () {
      return import(
        /* webpackChunkName: "login" */ "../modules/auth/Login.vue"
      );
    },
  },
  {
    path: "/logout",
    name: "Logout",
    meta: { hasSidebar: false },
    component: function () {
      return import(
        /* webpackChunkName: "logout" */ "../modules/auth/Logout.vue"
      );
    },
  },
  {
    path: "/authenticate",
    name: "Authenticate",
    meta: { hasSidebar: false },
    component: function () {
      return import(
        /* webpackChunkName: "authenticate" */ "../modules/auth/Authenticate.vue"
      );
    },
  },
  {
    path: "/activate",
    name: "Activate",
    meta: { hasSidebar: false },
    beforeEnter: routeGuard,
    component: function () {
      return import(
        /* webpackChunkName: "activate" */ "../modules/account/Activate.vue"
      );
    },
  },
  {
    path: "/deactivate",
    name: "Deactivate",
    beforeEnter: routeGuard,
    meta: { hasSidebar: false },
    component: function () {
      return import(
        /* webpackChunkName: "deactivate" */ "../modules/account/Deactivate.vue"
      );
    },
  },
  {
    path: "/error",
    name: "Error",
    meta: { hasSidebar: false },
    component: function () {
      return import(
        /* webpackChunkName: "error" */ "../modules/error/Error.vue"
      );
    },
  },
  {
    path: "/not-found",
    name: "Not Found",
    meta: { hasSidebar: false },
    component: function () {
      return import(
        /* webpackChunkName: "error" */ "../modules/error/Error.vue"
      );
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    redirect: { path: "/not-found" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

export const NAVIGATION_EXTERNAL = [
    { name: "Knowledge Base", href: "https://www.preava.com/support" },
    { name: "Feedback", href: "https://www.preava.com/feedback" },
    { name: "File a Support Ticket", href: "https://support.preava.com/tickets-view/new" },
];

export const NAVIGATION_INTERNAL = [
    { name: "Dashboard", path: "/v2/dashboard" },
    { name: "Users", path: "/v2/users" },
    { name: "Rules", path: "/v2/rules" },
    { name: "Policies", path: "/v2/policies" },
    { name: "Logs", path: "/v2/logs" },
    { name: "Settings", path: "/v2/settings" },
    { name: "Endpoints", path: "/v2/endpoints" },
];

/**
 * @TODO:
 * This needs to be returned by the backend.
 * For now, we map this locally.
 */
export const IMPLEMENTATION_TYPE_MAP = new Map([
    ['AUTODETECTINTERNAL', 'POLICY'],
    ['CUSTOMDERISKEMAIL', 'RULE'],
    ['CUSTOMDERISKDOMAIN', 'RULE'],
    ['CUSTOMCOMBINATIONSEMAIL', 'RULE'],
    ['CUSTOMCOMBINATIONSDOMAIN', 'RULE'],
    ['AUTODERISKDOMAIN', 'POLICY'],
    ['AUTOCOMBINATIONSEMAIL', 'POLICY'],
    ['CONTENTSIMPLE', 'RULE'],
    ['CONTENTREGEX', 'RULE'],
    ['CONTENTCOMPLIANCE', 'POLICY'],
    ['HISTOGRAMEMAIL', 'POLICY'],
    ['HISTOGRAMDOMAIN', 'POLICY'],
    ['DISTRIBUTIONEMAIL', 'POLICY'],
    ['DISTRIBUTIONDOMAIN', 'POLICY'],
    ['GRAPHEMAIL', 'POLICY'],
    ['GRAPHDOMAIN', 'POLICY'],
    ['USEROPTOUTEMAIL', 'POLICY'],
    ['USEROPTOUTDOMAIN', 'POLICY']
]);
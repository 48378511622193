<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="735"
    height="217"
    viewBox="0 0 735 217"
    fill="none"
  >
    <path
      d="M269.16 92C274.733 92 279.573 92.9387 283.68 94.816C287.787 96.6347 290.955 99.2747 293.184 102.736C295.413 106.139 296.528 110.157 296.528 114.792C296.528 119.427 295.413 123.445 293.184 126.848C290.955 130.251 287.787 132.891 283.68 134.768C279.573 136.587 274.733 137.496 269.16 137.496H258.424V153.6H241V92H269.16ZM268.104 123.768C271.683 123.768 274.381 123.005 276.2 121.48C278.019 119.896 278.928 117.667 278.928 114.792C278.928 111.917 278.019 109.688 276.2 108.104C274.381 106.52 271.683 105.728 268.104 105.728H258.424V123.768H268.104Z"
      fill="#1D293F"
    />
    <path
      d="M348.671 137.232H339.167V153.6H321.743V92H349.903C355.477 92 360.317 92.9387 364.423 94.816C368.53 96.6347 371.698 99.2747 373.927 102.736C376.157 106.139 377.271 110.157 377.271 114.792C377.271 119.251 376.215 123.152 374.103 126.496C372.05 129.781 369.087 132.363 365.215 134.24L378.503 153.6H359.847L348.671 137.232ZM359.671 114.792C359.671 111.917 358.762 109.688 356.943 108.104C355.125 106.52 352.426 105.728 348.847 105.728H339.167V123.768H348.847C352.426 123.768 355.125 123.005 356.943 121.48C358.762 119.896 359.671 117.667 359.671 114.792Z"
      fill="#1D293F"
    />
    <path
      d="M453.06 140.136V153.6H403.604V92H451.916V105.464H420.852V115.848H448.22V128.872H420.852V140.136H453.06Z"
      fill="#1D293F"
    />
    <path
      d="M520.721 141.632H494.673L489.833 153.6H472.057L499.249 92H516.409L543.689 153.6H525.561L520.721 141.632ZM515.617 128.784L507.697 109.072L499.777 128.784H515.617Z"
      fill="#1D293F"
    />
    <path
      d="M624.391 92L597.991 153.6H580.831L554.519 92H573.351L590.071 132.128L607.143 92H624.391Z"
      fill="#1D293F"
    />
    <path
      d="M683.841 141.632H657.793L652.953 153.6H635.177L662.369 92H679.529L706.809 153.6H688.681L683.841 141.632ZM678.737 128.784L670.817 109.072L662.897 128.784H678.737Z"
      fill="#1D293F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M128.327 34.7539C151.755 32.3622 180.116 25.5028 200.895 5.93359C197.017 17.5637 191.22 26.3047 184.261 32.8325C189.219 29.7517 193.955 26.1799 198.352 22.0391C193.313 37.1519 185.032 47.3862 175.176 54.2251C178.063 59.5211 179.703 65.5943 179.703 72.0508V111.814C179.703 134.795 161.073 153.426 138.091 153.426H109.192C97.5869 153.426 88.1764 162.824 88.1563 174.425V191.133C88.1563 196.061 89.9371 200.823 93.1707 204.541C94.5237 206.097 93.4185 208.523 91.3566 208.523H86.5801C57.4892 208.523 33.9063 184.948 33.9063 155.857L33.9063 137.819L33.9062 137.748V77.1367C33.9062 53.7293 52.8817 34.7539 76.2891 34.7539H128.327ZM103.414 87.3086C94.9874 87.3086 88.1562 94.1397 88.1562 102.566H111.043C119.47 102.566 126.301 95.7353 126.301 87.3086H103.414Z"
      fill="#3676CA"
    />
    <path
      d="M179.279 66.1172C157.26 75.0825 128.916 75.1251 112.314 74.0264C150.097 73.1896 175.226 54.25 175.226 54.25C177.584 58.4883 178.432 61.8789 179.279 66.1172Z"
      fill="#1F5BAA"
    />
    <path
      d="M126.276 87.3108C138.937 87.2748 152.317 86.694 166.963 80.1035C152.663 89.7772 137.841 96.5686 123.334 96.6328C124.349 94.5908 126.275 89.7456 126.276 87.3108Z"
      fill="#1F5BAA"
    />
    <path
      d="M88.1562 102.566H107.652C95.3613 108.076 90.2754 113.586 88.1562 165.293V102.566Z"
      fill="#1F5BAA"
    />
  </svg>
</template>
<!-- A component shown when the user attempts to view the app in mobile/smaller screen -->
<template>
  <div class="screen__container">
    <div class="screen_inner__container">
      <div class="screen_inner__wrapper">
        <div class="screen_inner__logo">
          <Logo />
        </div>
        <h2 class="screen_inner__title">
          Larger Viewport Required
        </h2>
        <p class="screen_inner__message">
          Please expand the width of your web browser. Mobile users, please open the dashboard in a desktop browser.
        </p>
        <p class="screen_inner__cta">
          Don't have an account?
          {{ " " }}
          <a
            href="https://www.preava.com/contact"
            target="_blank"
            class="screen_inner__link"
          >
            Contact us
          </a>
        </p>      
      </div>
    </div>
    <div class="screen_outer__container">
      <a 
        href="https://www.preava.com/legal/privacy-notice?hsLang=en" 
        target="_blank" 
        class="screen_outer__link"
      >
        Privacy Policy
      </a> 
      <span class="screen_outer__separator">|</span>
      <a 
        href="https://www.preava.com/legal/application-privacy-notice?hsLang=en" 
        target="_blank" 
        class="screen_outer__link"
      >
        Application Privacy Policy
      </a>
    </div>
  </div>
</template>
<script>
import Logo from "@/assets/logos/PreavaLogo";

export default {
  components: {
    Logo,
  },
};
</script>
<style scoped>
  @import './screen.css';
</style>
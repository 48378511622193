import axios from "axios";
import moment from "moment";
import { v4 } from "uuid";
import { getTokenSilently } from "@/modules/v2/utilities";
import { GRPC_ERROR } from "@/modules/v2/utilities";
import { getApi } from "prv-js-common/endpoints";

export const getMetadata = async (reqType, traceId) => {
  /**
   * NOTE ABOUT TOKEN
   *
   * This is taken care of in the Auth0 SPA.
   * If there's a valid (non-expired) token, it returns that.
   * Otherwise, it fetches a new one.
   */
  let token = await getTokenSilently();
  // let token = await getTokenSilently();

  /**
   * NOTE ABOUT TIMEOUT
   *
   * In order for us to set these timeouts in one place
   * (i.e. whenever we decide to change them),
   * we have to do a switch statement here instead of passing
   * the timeout value as a param every time a request is made.
   * We can also add more request types with different timeouts here.
   */
  let timeout = 2500; //default
  switch (reqType) {
    case "activate":
      timeout = 15000;
      break;
    case "deactivate":
      timeout = 15000;
      break;
    case "preflight":
      timeout = 5000;
      break;
    default:
      break;
  }
  let dt = new Date();
  dt.setSeconds(dt.getSeconds() + timeout); // TODO: 15s is for Activation/Deactivation

  /**
   * NOTE ABOUT x-trace-id AND x-request-id:
   *
   * Here the x-trace-id / Correlation ID is being passed by the parent component.
   * Since we have to generate a Correlation ID every time the Activation is called.
   * We then use this through out every page's request until a new Activation request is called.
   * This property can also be retrieved via `metadata['x-trace-id]` when the metadata object is returned below.
   *
   * As for the x-request-id, it is generated every time a request is made. This can
   * then be retrieved via `metadata['x-request-id]` when the metadata object is returned below.
   */

  /**
   *
   * Feature Override:
   * The x-trace-id or traceId will now be stored in local storage to
   * be reused again and again until the user logs out. Once the user
   * logs out, we delete the traceId.
   *
   * Date: September 6, 2023
   * Updated by: Johnny
   * Requested by: Michele
   *
   */

  // check for the local storage if there are any stored
  let xTraceId = retrieveFromLocalStorage();
  console.log(xTraceId);
  if (xTraceId == null) {
    // no x-trace-id found in local storage. Create one.
    xTraceId = v4();
    saveToLocalStorage(xTraceId);
    console.log("no xTraceId found in local storage. Creating a new one:", xTraceId);
  } else {
    console.log("xTraceId found in local storage:", xTraceId);
  }

  return {
    Authorization: `Bearer ${token}`,
    "x-request-id": v4(),
    // "x-trace-id": traceId,
    "x-trace-id": xTraceId,
    deadline: dt.getTime(),
  };
};

/**
 *
 * Methods for accessing x-trace-id in the local storage
 *
 * Date: September 6, 2023
 * Updated by: Johnny
 * Requested by: Michele
 */

const traceIdKey = "preavaXTraceId";

const saveToLocalStorage = (value) => {
  // Check if window and localStorage are available
  if (typeof window !== "undefined" && window.localStorage) {
    localStorage.setItem(traceIdKey, value);
  }
};

export const retrieveFromLocalStorage = () => {
  // Check if window and localStorage are available
  if (typeof window !== "undefined" && window.localStorage) {
    const value = localStorage.getItem(traceIdKey);
    return value ? value : null;
  }
};

export const deleteFromLocalStorage = () => {
  // Check if window and localStorage are available
  if (typeof window !== "undefined" && window.localStorage) {
    localStorage.removeItem(traceIdKey);
  }
};

export const getGrpcOptions = (options) => {
  return {
    suppressCorsPreflight: false,
  };
};

export const enumToString = (e, v) => {
  for (var k in e) if (e[k] == v) return k;
  return null;
};

export const grpcErrorToString = (err_code) => {
  return enumToString(GRPC_ERROR, err_code);
};

export const camelCaseString = (string) => {
  let sArr = string.toLowerCase().split("_");
  if (sArr.length > 1) {
    sArr.forEach((e, i) => {
      sArr[i] = e[0].toUpperCase() + e.slice(1);
    });
    return sArr.join(" ");
  } else {
    let e = string.toLowerCase();
    return (e && e[0].toUpperCase() + e.slice(1)) || "";
  }
};

export const getAdminDashVersion = () => {
  return new Promise(async (resolve, reject) => {
    console.log(`[🫱🏼HELPER]: Retreiving admin dash version...`);
    // If production, fetch the version.json. If development, skip
    if (process.env.NODE_ENV === "production") {
      axios
        .get("/version.json")
        .then((response) => {
          resolve({
            version: response.data.tag,
            date: moment.unix(response.data.timestamp).format("YYYY-MM-DD hh:mm:ss"),
          });
        })
        .catch((err) => {
          console.log("An error occurred while fetching version =>", err);
        });
    } else {
      resolve({
        version: "development",
        date: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
    }
  });
};

export const getOutlookAddinVersion = () => {
  return new Promise(async (resolve, reject) => {
    console.log(`[🫱🏼HELPER]: Retreiving outlook add-in version...`);
    if (process.env.NODE_ENV === "production") {
      let manifestVersionUrl = `https://${location.host}/prevent/outlook/on-send/version.json`;
      manifestVersionUrl = manifestVersionUrl.replace("admin", "dist");
      console.log(`fetching `, manifestVersionUrl);
      axios
        .get(manifestVersionUrl)
        .then((response) => {
          resolve({
            version: response.data.tag,
            date: moment.unix(response.data.timestamp).format("YYYY-MM-DD hh:mm:ss"),
          });
        })
        .catch((err) => {
          console.log("An error occurred while fetching version =>", err);
        });
    } else {
      resolve({
        version: "development",
        date: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
    }
  });
};

export const getEndpoint = () => {
  return location.host.includes(":8080") ? process.env.VUE_APP_TEST_ENDPOINT : getApi(location.host);
};

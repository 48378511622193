import { defineStore } from "pinia";
import { ref } from "vue";
import { v4 } from "uuid";

const PREFIX = '💽STORE⟹SESSION';

export const useSessionStore = defineStore('v2_session', () => {

    /**
     * The user information of the logged in user
     */
    const isWaiting = ref(true);
    const isInitializing = ref(true);
    const traceId = ref('');
    const errorMessage = ref('');
    const adminDashVersion = ref('');
    const outlookAddinVersion = ref('');
    
    // Rule Session UI
    const isSlideOverOpen = ref(false);
    const isFilterSlideOverOpen = ref(false);
    const isConfirmExitOpen = ref(false);
    const newRuleSelectedRuleType = ref('');
    const showErrorMessage = ref(false);

    const role = ref(null)


    /**
     * Get the identity of the loggedin user
     */
    const createNewTraceId = () => {
        traceId.value = v4();
        return { isSuccess: true }
    }

    const setRole = (_role) => {
        role.value = _role
    }

    return { 
        traceId, isInitializing , isWaiting, errorMessage, role, 
        adminDashVersion, outlookAddinVersion, isSlideOverOpen, isFilterSlideOverOpen, isConfirmExitOpen, newRuleSelectedRuleType, showErrorMessage,
        createNewTraceId, setRole
    }
})
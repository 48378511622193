<!--- NOTE: This is the default Header used throughout the App -->

<template>
  <header class="header__wrapper">
    <div class="header__section__logo">
      <!-- 
        [TODO]: 
        
        Please convert the "/settings" route as a variable (i.e. "HOME_URL") to 
        easily change this URL in the future. The "HOME_URL" variable should be
        stored in a global config file.
        - by @johnnypreavacom
        
      -->
      <router-link to="/" class="header__logo">
        <PreavaNameLogo />
      </router-link>
    </div>
    <div v-if="showMenu" class="header__section__menu" >
      <!-- The menu can be shown or hidden on specific pages -->
      <UserMenu :version="version" />
    </div>

    <!-- 
      
      [IMPORTANT]:

      Since Preava Admin should only be opened on Desktop view, 
      all mobile menu components are removed. If we decide to 
      have mobile capabilities in the future, we need to add 
      these components here.
      - by @johnnypreavacom

    -->

  </header>
</template>
<script>
import UserMenu from "./subcomponents/UserMenu.vue"
// import Logo from "@/assets/logos/PreavaLogo";
import PreavaNameLogo from "@/assets/logos/PreavaNameLogo";
import axios from "axios";

export default {
  components: {
    UserMenu,
    // Logo,
    PreavaNameLogo
  },
  data() {
    return {
      showMenu: true,
      version: null
    }
  },
  created() {
    
    // If production, fetch the version.json. If development, skip
    if (process.env.NODE_ENV === "production") {
      axios
        .get("/version.json")
        .then((response) => this.version = response.data.tag )
        .catch((err) =>
          console.log("An error occurred while fetching version =>", err)
        );
    }else{
      this.version = "development"
    }

    // If it's the Error page, don't show the menu
    if(this.$route.path == '/error'){
      this.showMenu = false
    }
    
  },
};
</script>
<style scoped>
  @import './header.css';
</style>